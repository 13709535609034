import { Injectable } from '@angular/core';
import { ConstantsService } from '../constants/constants.service';
import { UserService } from '../user/user.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PricingWorkbenchService } from '../../../services/pricing-workbench.service';
import { catchError, map, retryWhen, skipWhile, switchMap } from 'rxjs/operators';
import { User } from '@xpo-ltl/sdk-common';
import { AdminPersonnel, PricingCode } from '@xpo-ltl/sdk-pricingworkbench';
import _ from 'lodash';
import { genericRetryStrategy } from '../../utils/rxjs-utils';
import { PricingCodesService } from '../../../services/pricing-codes.service';
import { UserPermission } from '../../enums/user-permission.enum';

@Injectable({
  providedIn: 'root',
})
export class UserPermissionService {
  userPermissions = new BehaviorSubject<Array<string>>(undefined);
  userPermissions$ = this.userPermissions.asObservable();

  constructor(
    private constants: ConstantsService,
    private userService: UserService,
    private pricingCodes: PricingCodesService,
    private pwbService: PricingWorkbenchService
  ) {
    this.userService.user$.pipe(skipWhile((user) => !user)).subscribe((user: User) => {
      this.loadPermissions();
    });
  }

  loadPermissions() {
    this.getPermissions().subscribe((permissions) => {
      this.userPermissions.next(permissions);
    });
  }

  getPermissions(): Observable<Array<string>> {
    return this.pwbService.getLoggedInAnalyst().pipe(
      switchMap((personnel: AdminPersonnel) => {
        const employeeRoleType = personnel.employeeRoleType ? personnel.employeeRoleType.trim() : '';
        const additionalRoleType = personnel.additionalRoleType ? personnel.additionalRoleType.trim() : '';
        const roles = [];
        if (employeeRoleType && !roles.includes(employeeRoleType)) {
          roles.push(employeeRoleType);
        }
        if (additionalRoleType) {
          additionalRoleType.split(',').forEach((additionalRole) => {
            const role = additionalRole ? additionalRole.trim() : '';
            if (role && !roles.includes(role)) {
              roles.push(role);
            }
          });
        }
        return this.pricingCodes.getPricingCodes().pipe(
          switchMap((response: PricingCode[]) => {
            const permissions = _.filter(response, (permission) => {
              return (
                permission.category === this.constants.AGREEMENT_MANAGEMENT &&
                permission.subCategory === this.constants.SECURITY &&
                roles.includes(permission.code)
              );
            }).map((permission: PricingCode) => permission.name);
            if (personnel) {
              permissions.push(UserPermission.RulesetDetail);
            }
            const result = [...new Set(permissions)];
            return of(result);
          })
        );
      }),
      retryWhen(
        genericRetryStrategy({
          scalingDuration: 2000,
          excludedStatusCodes: [404],
        })
      ),
      catchError(() => {
        return of([]);
      })
    );
  }

  hasPermission(permission: string): Observable<boolean> {
    if (this.userPermissions.value) {
      return of(this.userPermissions.value.includes(permission));
    } else {
      return this.getPermissions().pipe(
        map((data) => {
          return data.includes(permission);
        })
      );
    }
  }
}
