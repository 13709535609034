import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { User } from '@xpo-ltl/sdk-common';
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { AppTitle } from './shared/enums/app-title.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';

import * as mockAppData from './data/application-categories.json';
import { UserRoleService } from './shared/services/user-role/user-role.service';
import { ApplicationUtilsService } from './services/application-utils/application-utils.service';
import { ReleaseNotesComponent } from './shared/components/release-notes/release-notes.component';
import { filter, skipWhile, take, takeUntil, tap } from 'rxjs/operators';
import { ReleaseNotesService } from './services/release-notes/release-notes.service';
import { BehaviorSubject, interval, Subject } from 'rxjs';

import { invoke as _invoke, isEmpty as _isEmpty } from 'lodash';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserService } from './shared/services/user/user.service';
import { UserPermissionService } from './shared/services/user-permission/user-permission.service';
import { UserPermission } from './shared/enums/user-permission.enum';
import { XpoShellRoute } from '@xpo-ltl/ngx-ltl-core/shell';
import { XpoApplication, XpoApplicationCategory } from '@xpo-ltl/ngx-ltl-core/application-switcher';
import { XpoAccountPopoverConfig } from '@xpo-ltl/ngx-ltl-core/account-popover';
import { XpoNotificationPopoverConfig } from '@xpo-ltl/ngx-ltl-core/notification-popover';
import { AuditAccessRoleBasedService } from './audit/services/audit-access-role-based.service';

interface XpoShellRouteSecure extends XpoShellRoute {
  permission?: UserPermission;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title: string;
  build: string;
  routes: Array<any>;
  readonly AccountUrls = AccountUrls;
  destroy$: Subject<any> = new Subject();

  applicationCategories: XpoApplicationCategory[] = [];
  accountPopoverProfileConfig: XpoAccountPopoverConfig;
  notifications: XpoNotificationPopoverConfig[];
  auditLabel = 'Audit';
  showMenuTagForAudit = false;
  userRoutes = new BehaviorSubject<XpoShellRoute[]>([]);
  userRoutes$ = this.userRoutes.asObservable();

  constructor(
    private loggedInUserService: XpoLtlLoggedInUserService,
    private configManagerService: ConfigManagerService,
    private authService: XpoLtlAuthenticationService,
    public releaseNotesService: ReleaseNotesService,
    public applicationUtilsService: ApplicationUtilsService,
    public userService: UserService,
    private userRole: UserRoleService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private userPermissionService: UserPermissionService,
    private auditAccessRoleBasedService: AuditAccessRoleBasedService
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      // Aquí puedes verificar la URL actual y tomar decisiones sobre la visibilidad del menú
      const currentUrl = this.route.snapshot.firstChild.url[0].path;
      if (
        currentUrl === AppRoutes.STANDALONE_RFP ||
        currentUrl === AppRoutes.STANDALONE_RULESET ||
        currentUrl === 'auth'
      ) {
        this.routes = [];
      } else {
        this.routes = [
          {
            label: 'Agreement List',
            path: `/${AppRoutes.AGREEMENT_LIST}/${AppRoutes.LIST}`,
            permission: UserPermission.AgreementList,
          },
          {
            label: 'Work Queue',
            path: `/${AppRoutes.RULESET_BUILDER}`,
            permission: UserPermission.RulesetBuilder,
          },
          {
            label: 'Admin',
            path: null,
            children: [
              {
                label: 'AC Services',
                path: `/${AppRoutes.ACCESSORIAL_SERVICES}`,
                permission: UserPermission.AccessorialServices,
              },
              {
                label: 'AC Groups',
                path: `/${AppRoutes.ACCESSORIAL_GROUPS}`,
                permission: UserPermission.AccessorialGroups,
              },
              {
                label: 'FSC Template',
                path: `/${AppRoutes.FSC_TEMPLATE}`,
                permission: UserPermission.FscTemplate,
              },
              {
                label: 'Ruleset View',
                path: `/${AppRoutes.RULESET_VIEW}`,
                permission: UserPermission.RulesetView,
              },
            ],
          },
          /*{
            label: 'Customer Hierarchy',
            path: `/${AppRoutes.CUSTOMER_HIERARCHY}`,
           },*/
          {
            label: 'OFFSHORE',
            path: null,
            children: [
              {
                label: 'FUEL PRICE',
                path: `/${AppRoutes.FUEL_PRICE}`,
                permission: UserPermission.FuelPrice,
              },
              {
                label: 'ZONE POINT',
                path: `/${AppRoutes.ZONE_POINTS}`,
                permission: UserPermission.ZonePoints,
              },
              {
                label: 'AGREEMENTS',
                path: `/${AppRoutes.AGREEMENTS}`,
                permission: UserPermission.Agreements,
              },
            ],
          },
        /*  {
            label: 'PL Docs',
            path: `/${AppRoutes.PL_DOCS}`,
          },*/
          {
            label: 'Audit',
            path: `/${AppRoutes.AUDIT}`,
            permission: UserPermission.Audit,
          },
          {
            label: 'Ruleset Detail',
            path: `/${AppRoutes.RULESET_DETAIL}`,
            permission: UserPermission.RulesetDetail,
          },
        ];
      }
      this.getRoutesBasedOnPermission();
    });

    /** Shell setup */
    this.title = AppTitle.agreementManagement;
    this.getBuildVersion();
  }

  private static getProfilePictureUrl(email: string): string {
    return `${AccountUrls.switchApiUrl}${email}${AccountUrls.pictureUrl}`;
  }

  getRoutesBasedOnPermission() {
    this.userPermissionService.userPermissions$
      .pipe(filter((permissions) => permissions !== undefined))
      .subscribe((permissions) => {
        const hasPermissions = permissions && permissions.length > 0;
        if (hasPermissions) {
          const permissionsBasedRoutes = this.getUserRoutes(this.routes, permissions);
          this.userRoutes.next(permissionsBasedRoutes);
          if (this.router.url === AppRoutes.ROOT) {
            if (permissions.includes(UserPermission.AgreementList)) {
              this.router.navigate([`${AppRoutes.AGREEMENT_LIST}/${AppRoutes.LIST}`]);
            }
          }
        } else {
          this.router.navigate([AppRoutes.NOT_AUTHORIZED_PAGE]);
        }
      });
  }

  ngOnInit(): void {
    this.handleLoggedInUser();
    this.loggedInUserService.userLoggedIn$.subscribe(() => {
      this.handleLoggedInUser();
    });

    const region = this.configManagerService.getSetting<string>(ConfigManagerProperties.region);
    this.authService.initAuthSetup$(region).subscribe();

    this.setApplicationCategories();
    this.setNotifications();

    this.checkForUpdatedReleaseNotes();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleLoggedInUser(): void {
    this.loggedInUserService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .subscribe(
        (user: User) => {
          if (user) {
            console.log(`You're logged in as user `, user);
            this.userService.User = user;
            this.setProfileConfig(user);
            this.setDynatraceUserIdentity(user);
            // send
            this.userRole.User = user;
          }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }

  private getUserRoutes(routes: XpoShellRouteSecure[], permissions: Array<string>): XpoShellRoute[] {
    const result = routes
      .filter((route: XpoShellRouteSecure) => {
        if (!route.children) {
          return !route.permission || permissions.includes(route.permission);
        } else {
          const child = route.children.filter(
            (children: any) => !children.permission || permissions.includes(children.permission)
          );
          return !!child.length;
        }
      })
      .map((route: XpoShellRouteSecure) => {
        return {
          label: route.label,
          path: route.path,
          children: route.children
            ? route.children.filter(
                (children: any) => !children.permission || permissions.includes(children.permission)
              )
            : null,
        };
      });
    return result;
  }

  getBuildVersion(): void {
    this.build = this.applicationUtilsService.buildVersion;
  }

  releaseNotesOpen(): void {
    window.open(this.applicationUtilsService.releaseNotesLink);
  }

  showReleaseNotes(): void {
    const dialogRef = this.dialog.open(ReleaseNotesComponent);
    dialogRef.componentInstance.viewNow.pipe(take(1)).subscribe(() => this.releaseNotesOpen());
    this.dialog
      ._getAfterAllClosed()
      .pipe(take(1))
      .subscribe(() => this.releaseNotesSelected());
  }

  releaseNotesSelected(): void {
    this.releaseNotesService.dismissNewReleaseNotes().pipe(takeUntil(this.destroy$)).subscribe();
  }

  releaseNotesSelectedAndOpen(): void {
    this.releaseNotesOpen();
    this.releaseNotesSelected();
  }

  async checkForUpdatedReleaseNotes(): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    this.releaseNotesService
      .doNewReleaseNotesExist()
      .pipe(take(1))
      .subscribe((doNewReleaseNotesExist: boolean) => {
        if (doNewReleaseNotesExist) {
          this.showReleaseNotes();
        }
      });
  }

  onFavoriteApplicationChange(application: XpoApplication): void {
    // add custom logic here
    console.log(application);
  }

  protected setApplicationCategories(): void {
    this.applicationCategories = (mockAppData as any).default;
  }

  protected setProfileConfig(user: User): void {
    this.accountPopoverProfileConfig = {
      name: user.displayName,
      onSignOutCallback: (): void => {
        this.signOut();
      },
      links: [],
      email: user.emailAddress,
    };
  }

  protected setNotifications(): void {
    this.notifications = [
      { title: 'Important notification 1', url: 'https://tcts3-app.ltl.xpo.com/appjs/ngx-ltl-core-docs' },
    ];
  }

  private signOut(): void {
    // Removing the local storage keys
    this.loggedInUserService.clear();
    localStorage.clear();
    sessionStorage.clear();
  }

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(
        window['dtrum'],
        'identifyUser',
        !_isEmpty(user.emailAddress) ? user.emailAddress : !_isEmpty(user.userId) ? user.userId : 'AM_USER'
      );

    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }
}
