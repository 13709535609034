import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setDefaultValue'
})
export class SetDefaultValuePipe implements PipeTransform {

  transform(value: string, defaultValue: string): string {
    return value ?? defaultValue;
  }
}
