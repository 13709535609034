import { Injectable } from '@angular/core';
import _ from 'lodash';
import { EmployeeRoleEnum } from '../../shared/enums/employee-role.enum';
import { PricingWorkbenchService } from '../../services/pricing-workbench.service';
import { AdminPersonnel } from '@xpo-ltl/sdk-pricingworkbench';

@Injectable({
  providedIn: 'root'
})
export class AuditAccessRoleBasedService {
  loggedInAnalystDetails: AdminPersonnel;

  constructor(private pwbService: PricingWorkbenchService) {
    this.pwbService.loggedInAnalystDetailData.subscribe(resp => this.loggedInAnalystDetails = resp);
  }

  isManagerAccess(employee?: AdminPersonnel): boolean{
    const employeeDetails = employee? employee: this.loggedInAnalystDetails;
    return this.isPricingManagerInd(employeeDetails) || this.isAuditManagerInd(employeeDetails);
    }

  getViewAsPerRole(employee: AdminPersonnel, users): AdminPersonnel[] {
    if (_.isEmpty(employee)) return [];
    if(this.isManagerAccess(employee)) {
      return _.orderBy(_.filter(users,(i) => {
        return this.isManagerAccess(i) && i.employeeId!== employee.employeeId;
      }),'employeeFullName');
    }
    else{
      return _.orderBy(_.filter(users,(i) => {
        return this.isAuditorInd(i) && i.employeeId !== employee.employeeId;
      }),'employeeFullName');
    }
  }

  isAuditorInd(employee?){
    const roles = employee? employee.additionalRoleType: this.loggedInAnalystDetails.additionalRoleType;
    return roles? roles.split(',').includes(EmployeeRoleEnum.AUDITOR): false;
  }

  isAuditManagerInd(employee?){
    const roles = employee? employee.additionalRoleType: this.loggedInAnalystDetails.additionalRoleType;
    return roles ? roles.split(',').includes(EmployeeRoleEnum.AUDIT_MANAGER) : false;
  }

  isPricingAnalystInd(employee?){
    const employeeDetails = employee? employee: this.loggedInAnalystDetails;
    return employeeDetails.employeeRoleType === EmployeeRoleEnum.PRICING_ANALYST;
  }

  isPricingManagerInd(employee?){
    const employeeDetails = employee? employee: this.loggedInAnalystDetails;
    return employeeDetails.employeeRoleType === EmployeeRoleEnum.PRICING_MANAGER;
  }

}


