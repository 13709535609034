import { Injectable } from '@angular/core';
import { ConfigManagerProperties } from '../../shared/enums/config-manager-properties.enum';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { CsvExportParams } from 'ag-grid-community';
import { ConstantsService } from '../../shared/services/constants/constants.service';
import { ErrorPipe } from '../../shared/pipes/error/error.pipe';
import { XpoGrid } from '@xpo-ltl/ngx-grid';
import { XpoFacetFilterControlChangeEvent } from '@xpo-ltl/ngx-ltl-core/facet-filter';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class ApplicationUtilsService {
  constructor(public configManagerService: ConfigManagerService, public constantsService: ConstantsService) {
  }

  buildVersion: string = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  readonly helpLink =
    'https://xpologistics.sharepoint.com/sites/150/ttdd/LTLservicecenter/SitePages/Edge%20Accessorial%20Management.aspx';
  readonly releaseNotesLink =
    'https://xpologistics.sharepoint.com/sites/150/ttdd/LTLservicecenter/SitePages/Edge%20Accessorial%20Management%20Release%20Notes.aspx';

}
