import { Injectable } from '@angular/core';
import { ListPricingCodesQuery, PricingCode, PricingWorkbenchApiService } from '@xpo-ltl/sdk-pricingworkbench';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PricingCodesCategories, PricingCodesSubCategories } from '../enums/pricing-codes.enum';

export interface CodesQuery {
  category: string[];
  subCategory: string[];
}
@Injectable({
  providedIn: 'root',
})
export class PricingCodesService {
  private pricingCodesCache$: Observable<PricingCode[]>;

  private defaultCategories: string[] = [
    PricingCodesCategories.plDocs,
    PricingCodesCategories.pricingAnalysis,
    PricingCodesCategories.qrb,
    PricingCodesCategories.salesRfp,
    PricingCodesCategories.agreementManagement,
  ];
  private defaultSubCategories: string[] = [
    PricingCodesSubCategories.docType,
    PricingCodesSubCategories.recordType,
    PricingCodesSubCategories.security,
    PricingCodesSubCategories.nMFCClass,
    PricingCodesSubCategories.displayItems,
    PricingCodesSubCategories.maintenanceStatusFilter,
    PricingCodesSubCategories.maintenanceTypeFilter,
    PricingCodesSubCategories.maintenanceDefaultFilter,
    PricingCodesSubCategories.tagAuditFlag,
  ];

  constructor(private pricingWorkbenchApiService: PricingWorkbenchApiService) {
  }

  listPricingCodes(params: CodesQuery = null) {
    const query = new ListPricingCodesQuery();
    query.category = params ? params.category : this.defaultCategories;
    query.subCategory = params ? params.subCategory : this.defaultSubCategories;
    return this.pricingWorkbenchApiService.listPricingCodes(query).pipe(map((result) => result.pricingCodes));
  }

  getPricingCodes(): Observable<PricingCode[]> {
    if (!this.pricingCodesCache$) {
      this.pricingCodesCache$ = this.listPricingCodes().pipe(shareReplay());
    }
    return this.pricingCodesCache$;
  }
}
