import { Component, Inject, OnInit } from '@angular/core';
import { XPO_SNACKBAR_DETAILED_MESSAGE_DATA } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { MoreInfo } from '@xpo-ltl/sdk-common';

@Component({
  selector: 'app-snack-bar-message',
  templateUrl: './snack-bar-message.component.html',
  styleUrls: ['./snack-bar-message.component.scss']
})
export class SnackBarMessageComponent implements OnInit {

  constructor(
    @Inject(XPO_SNACKBAR_DETAILED_MESSAGE_DATA ) public errorList: MoreInfo[]
  ) {}

  ngOnInit(): void {
  }

}
